
<div
  class="selection:cpt-text-calenso-white-color selection:cpt-bg-calenso-neon-blue-color"
  [ngClass]="{'cpt-m-5': _renderMode === sales}"
>
  @if (_renderMode === sales) {
    <div class="cpt-text-center md:cpt-flex cpt-justify-between cpt-items-center cpt-mb-9">
      <h1 class="cpt-font-extrabold cpt-text-2xl">
        {{ 'pricing_table.calenso_pricing' | translate }}
      </h1>
      <div class="cpt-text-center md:cpt-flex cpt-gap-5">
        <div class="cpt-text-calenso-steel-blue-color cpt-text-sm md:cpt-text-md">
          <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _currency === chf}"
            (click)="setCurrency(chf)">CHF</span> |
          <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _currency === eur}"
            (click)="setCurrency(eur)">EUR</span>
        </div>

        <div class="cpt-text-calenso-steel-blue-color cpt-text-sm md:cpt-text-md">
          <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _language === en_US}"
            (click)="setlanguage(en_US)">English</span> |
          <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _language === de_CH}"
            (click)="setlanguage(de_CH)">German</span>
        </div>
      </div>
    </div>
  }

  <div>
    <div class="cpt-border cpt-border-solid cpt-rounded cpt-p-3 cpt-text-base cpt-font-semibold">
      <div class="cpt-flex cpt-flex-col-reverse cpt-items-center cpt-gap-3 md:cpt-flex-row md:cpt-justify-between">
        @if (_showCustomPlanSection) {
          <div class="cpt-text-sm md:cpt-text-md">
            <span class="cpt-text-calenso-neon-blue-color cpt-cursor-pointer cpt-border-b-2 cpt-border-calenso-neon-blue-color" (click)="toggleUserProfile()">
              @if(isCustomPlanSelectionEnabled) {
                {{ "pricing_table.close" | translate }}
              } @else {
                {{ "pricing_table.manage_user_profile" | translate }}
              }
            </span>
          </div>
        } @else {
          <div></div>
        }

        <div class="cpt-text-calenso-steel-blue-color cpt-text-sm md:cpt-text-md" dir="rtl">
          <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _interval === monthly}"
            (click)="toggleInterval(monthly)">{{ "pricing_table.monthly" | translate }}</span> |
          <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _interval === yearly}"
            (click)="toggleInterval(yearly)">{{ "pricing_table.annual" | translate }}
            <span class="cpt-ml-1 cpt-rounded cpt-bg-calenso-powder-blue-color cpt-px-2 cpt-py-1">{{
              "pricing_table.save_20" | translate }}</span>
          </span>
          @if (_renderMode === sales && this._showMoreInterval) {
            | <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _interval === year_2}"
              (click)="toggleInterval(year_2)">{{ "pricing_table.year_2" | translate }}
                <span class="cpt-ml-1 cpt-rounded cpt-bg-calenso-powder-blue-color cpt-px-2 cpt-py-1">{{
                "pricing_table.save_25" | translate }}</span>
            </span>
            | <span class="cpt-cursor-pointer" [ngClass]="{'cpt-text-calenso-neon-blue-color': _interval === year_3}"
              (click)="toggleInterval(year_3)">{{ "pricing_table.year_3" | translate }}
              <span class="cpt-ml-1 cpt-rounded cpt-bg-calenso-powder-blue-color cpt-px-2 cpt-py-1">{{
                "pricing_table.save_30" | translate }}</span>
            </span>
          }
        </div>
      </div>
    </div>
    @if (isCustomPlanSelectionEnabled && _showCustomPlanSection) {
      <div class="cpt-border cpt-border-solid cpt-rounded cpt-mt-4 cpt-p-3 cpt-text-base cpt-font-semibold">
        <div class="cpt-grid cpt-gap-2 md:cpt-grid-cols-2 lg:cpt-grid-cols-4">
          <div class="cpt-text-center">
            <p class="calenso-custom-plan-title">{{ "pricing_table.change_number_of_user" | translate }}</p>
            <p class="calenso-custom-plan-subtitle">{{ "pricing_table.user_choose_number" | translate }}:</p>
            <div class="cpt-flex cpt-flex-col cpt-mt-2 cpt-gap-3 md:cpt-flex-row md:cpt-justify-between">
              <div class="cpt-flex cpt-justify-center md:cpt-justify-start cpt-gap-2">
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanUser <= minLimit"
                  (click)="decrement(customPlanUserType)"
                >
                <ng-container [ngTemplateOutlet]="decrementButtonText" />
                </button>
                <input
                  type="number"
                  name="price"
                  class="calenso-input"
                  [(ngModel)]="customPlanUser"
                  appMaxInputValue="{{ maxLimit }}"
                  (keyup)="changeUserProfile(customPlanUser, customPlanUserType)"
                >
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanUser >= maxLimit"
                  (click)="increment(customPlanUserType)"
                >
                  <ng-container [ngTemplateOutlet]="incrementButtonText" />
                </button>
              </div>
            </div>
          </div>
          <div class="cpt-text-center">
            <p class="calenso-custom-plan-title">{{ "pricing_table.change_number_of_store" | translate }}</p>
            <p class="calenso-custom-plan-subtitle">{{ "pricing_table.store_choose_number" | translate }}:</p>
            <div class="cpt-flex cpt-flex-col cpt-mt-2 cpt-gap-3 md:cpt-flex-row md:cpt-justify-between">
              <div class="cpt-flex cpt-justify-center md:cpt-justify-start cpt-gap-2">
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanStore <= minLimit"
                  (click)="decrement(customPlanStoreType)"
                >
                  <ng-container [ngTemplateOutlet]="decrementButtonText" />
                </button>
                <input
                  type="number"
                  name="price"
                  class="calenso-input"
                  [(ngModel)]="customPlanStore"
                  appMaxInputValue="{{ maxLimit }}"
                  (keyup)="changeUserProfile(customPlanStore, customPlanStoreType)"
                >
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanStore >= maxLimit"
                  (click)="increment(customPlanStoreType)"
                >
                  <ng-container [ngTemplateOutlet]="incrementButtonText" />
                </button>
              </div>
            </div>
          </div>
          <div class="cpt-text-center">
            <p class="calenso-custom-plan-title">{{ "pricing_table.change_number_of_group_appointment" | translate }}</p>
            <p class="calenso-custom-plan-subtitle">{{ "pricing_table.group_appointment_choose_number" | translate }}:</p>
            <div class="cpt-flex cpt-flex-col cpt-mt-2 cpt-gap-3 md:cpt-flex-row md:cpt-justify-between">
              <div class="cpt-flex cpt-justify-center md:cpt-justify-start cpt-gap-2">
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanGroupAppointment <= minLimit"
                  (click)="decrement(customPlanGroupAppointmentType)"
                >
                  <ng-container [ngTemplateOutlet]="decrementButtonText" />
                </button>
                <input
                  type="number"
                  name="price"
                  class="calenso-input"
                  [(ngModel)]="customPlanGroupAppointment"
                  appMaxInputValue="{{ maxLimit }}"
                  (keyup)="changeUserProfile(customPlanGroupAppointment, customPlanGroupAppointmentType)"
                >
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanGroupAppointment >= maxLimit"
                  (click)="increment(customPlanGroupAppointmentType)"
                >
                  <ng-container [ngTemplateOutlet]="incrementButtonText" />
                </button>
              </div>
            </div>
          </div>
          <div class="cpt-text-center">
            <p class="calenso-custom-plan-title">{{ "pricing_table.change_number_of_conference" | translate }}</p>
            <p class="calenso-custom-plan-subtitle">{{ "pricing_table.conference_choose_number" | translate }}:</p>
            <div class="cpt-flex cpt-flex-col cpt-mt-2 cpt-gap-3 md:cpt-flex-row md:cpt-justify-between">
              <div class="cpt-flex cpt-justify-center md:cpt-justify-start cpt-gap-2">
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanConference <= minConferenceLimit"
                  (click)="decrement(customPlanConferenceType)"
                >
                  <ng-container [ngTemplateOutlet]="decrementButtonText" />
                </button>
                <input
                  type="number"
                  name="price"
                  class="calenso-input"
                  [(ngModel)]="customPlanConference"
                  appMaxInputValue="{{ maxLimit }}"
                  (keyup)="changeUserProfile(customPlanConference, customPlanConferenceType)"
                >
                <button
                  class="calenso-button outline cpt-w-9"
                  [disabled]="customPlanConference >= maxLimit"
                  (click)="increment(customPlanConferenceType)"
                >
                  <ng-container [ngTemplateOutlet]="incrementButtonText" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Custom plan pricing calculation start -->
        <div class="cpt-mt-4 cpt-flex cpt-justify-center md:cpt-justify-end md:cpt-self-end">
          <div class="cpt-max-w-fit cpt-text-center md:cpt-text-right">
            <div class="cpt-text-center md:cpt-text-left">
              @if (customSelectedPlan?.id !== enterpriseId || _renderMode === sales) {
                <span class="cpt-text-2xl">
                  {{ currencySymbol }} {{ customPlanTotalAmount | number: decimalDigits: decimalLocal }}
                </span>
                <span class="cpt-text-md"> / {{ "pricing_table.month" | translate }}</span>
                @if (
                  customSelectedPlan.min_workers < customPlanUser ||
                  customSelectedPlan.min_store < customPlanStore ||
                  customSelectedPlan.min_group_appointment < customPlanGroupAppointment ||
                  customSelectedPlan.min_conference < customPlanConference
                ) {
                  <div class="cpt-w-full cpt-border-b-2 cpt-border-calenso-powder-blue-color"></div>
                }
                <div class="cpt-text-sm">
                  @if (customSelectedPlan.min_workers < customPlanUser) {
                    <div>
                      <ng-container [ngTemplateOutlet]="incrementButtonText" />
                      @if((customPlanUser - customSelectedPlan.min_workers) === 1) {
                        {{ customPlanUser - customSelectedPlan.min_workers }} {{ "pricing_table.per_user_calender" | translate }}
                      } @else {
                        {{ customPlanUser - customSelectedPlan.min_workers }} {{ "pricing_table.per_user_calenders" | translate }}
                      }
                    </div>
                  }
                  @if (customSelectedPlan.min_store < customPlanStore) {
                    <div>
                      <ng-container [ngTemplateOutlet]="incrementButtonText" />
                      @if((customPlanStore - customSelectedPlan.min_store) === 1) {
                        {{ customPlanStore - customSelectedPlan.min_store }} {{ "pricing_table.per_store" | translate }}
                      } @else {
                        {{ customPlanStore - customSelectedPlan.min_store }} {{ "pricing_table.per_stores" | translate }}
                      }
                    </div>
                  }
                  @if (customSelectedPlan.min_group_appointment < customPlanGroupAppointment) {
                    <div>
                      <ng-container [ngTemplateOutlet]="incrementButtonText" />
                      @if((customPlanGroupAppointment - customSelectedPlan.min_group_appointment) === 1) {
                        {{ customPlanGroupAppointment - customSelectedPlan.min_group_appointment }} {{ "pricing_table.per_group_appointment" | translate }}
                      } @else {
                        {{ customPlanGroupAppointment - customSelectedPlan.min_group_appointment }} {{ "pricing_table.per_group_appointments" | translate }}
                      }
                    </div>
                  }
                  @if (customSelectedPlan.min_conference < customPlanConference) {
                    <div>
                      <ng-container [ngTemplateOutlet]="incrementButtonText" />
                      @if((customPlanConference - customSelectedPlan.min_conference) === 1) {
                        {{ customPlanConference - customSelectedPlan.min_conference }} {{ "pricing_table.per_conference" | translate }}
                      } @else {
                        {{ customPlanConference - customSelectedPlan.min_conference }} {{ "pricing_table.per_conferences" | translate }}
                      }
                    </div>
                  }
                </div>
              } @else {
                <div class="cpt-text-center md:cpt-text-right">
                  <span class="cpt-text-2xl">
                    {{ "pricing_table.price_on_request" | translate }}
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
        <!-- Custom plan pricing calculation end -->
      </div>
    }
    <div class="cpt-flex cpt-gap-6 md:cpt-gap-3 lg:cpt-gap-6 cpt-flex-col md:cpt-flex-row cpt-mt-6">
      @for (plan of pricingList; track plan.id) {
        <div
          [ngClass]="{
            'active-plan': (
            _renderMode === dashboard &&
            plan.id === _currentSubscription?.subscription_type_id &&
            _interval === _currentSubscription.subscription_interval),
            'popular-plan':(
              plan.name === _popularPlan &&
              _currentSubscription?.subscription_type_id !== subscriptionIds[_popularPlan]
            ),
            'cpt-opacity-50 disabled-plan': !availablePlans.includes(plan.id)
          }"
          class="popular-plan pricing-card cpt-bg-calenso-powder-blue-color cpt-p-3 cpt-pt-5 cpt-pb-14 cpt-rounded cpt-relative cpt-flex-1 [&.popular-plan]:cpt-bg-calenso-neon-blue-color"
        >
          <div class="-cpt-mt-5 cpt-flex cpt-justify-center cpt-gap-2">
            <!-- active-plan -->
            <div class="cpt-hidden -cpt-mb-3.5 -cpt-mt-3.5 [.active-plan_&]:cpt-block cpt-text-calenso-white-color cpt-bg-caleno-green-color cpt-max-w-fit cpt-font-semibold cpt-text-sm md:cpt-text-xs lg:cpt-text-sm cpt-p-1 cpt-px-3 cpt-rounded-md">
              {{ "pricing_table.active" | translate }}
            </div>
            <!-- popular-plan -->
            <div class="cpt-hidden -cpt-mb-3.5 -cpt-mt-3.5 [.popular-plan_&]:cpt-block cpt-text-calenso-neon-blue-color cpt-bg-calenso-powder-blue-color cpt-max-w-fit cpt-font-semibold cpt-text-sm md:cpt-text-xs lg:cpt-text-sm cpt-p-1 cpt-px-3 cpt-rounded-md">
              {{ "pricing_table.best_value" | translate }}
            </div>
          </div>
          <!-- Name -->
          <div
            class="cpt-mt-10 cpt-text-3xl md:cpt-text-2xl lg:cpt-text-3xl cpt-capitalize cpt-font-bold cpt-text-calenso-neon-blue-color [.popular-plan_&]:cpt-text-calenso-white-color">
            {{ plan.subscription_title }}
          </div>
          <!-- Description -->
          <div
            class="cpt-mt-3 cpt-text-sm md:cpt-text-xs lg:cpt-text-sm cpt-font-medium md:cpt-min-h-[130px] lg:cpt-min-h-[120px] xl:cpt-min-h-[100px] [.popular-plan_&]:cpt-text-calenso-white-color">
            {{ plan.subscription_subtitle[_language] }}
          </div>
          <!-- Price -->
          <div class="cpt-mt-8 [.popular-plan_&]:cpt-text-calenso-white-color">
            <span class="cpt-text-3xl cpt-font-bold md:cpt-text-2xl lg:cpt-text-3xl">
              @if (!plan.price_on_request ) {
                {{ currencySymbol }}
                @if (_showUserBasedPricing) {
                  {{ plan?.[_currency | lowercase]?.[_interval]?.original_price }}
                  <span class="cpt-text-sm md:cpt-text-xs lg:cpt-text-sm cpt-font-medium">
                    / {{ "pricing_table.per_user_calender" | translate }}
                  </span>
                } @else {
                  @if (plan?.name === appliedPlan?.name) {
                    {{ customPlanTotalAmount | number: decimalDigits: decimalLocal }}
                  } @else {
                    {{ plan?.[_currency | lowercase]?.[_interval]?.original_price | number: decimalDigits: decimalLocal}}
                  }
                  <span class="cpt-text-sm md:cpt-text-xs lg:cpt-text-sm cpt-font-medium">
                    / {{ "pricing_table.month" | translate }}
                  </span>
                }
              } @else {
                @if (_renderMode === website) {
                  <img width="50" height="50" src="{{ deployUrl + '/assets/images/chat_enteprise_pricing_black.svg' }}" alt="cancel" class="cpt-w-9 cpt-h-9 cpt-bg-calenso-powder-blue-color [.popular-plan_&]:cpt-hidden">
                  <img width="50" height="50" src="{{ deployUrl + '/assets/images/chat_enteprise_pricing_white.svg' }}" alt="cancel" class="cpt-w-9 cpt-h-9 cpt-hidden cpt-bg-calenso-neon-blue-color [.popular-plan_&]:cpt-block">
                } @else {
                  {{ "pricing_table.upon_request" | translate }}
                }
              }
              @if (!plan.price_on_request) {
                <div class="cpt-text-xs cpt-font-medium cpt-text-calenso-steel-blue-color [.popular-plan_&]:cpt-text-calenso-powder-blue-color">
                  @if (_showUserBasedPricing) {
                    + {{extraUserCost}} {{_currency}} {{ "pricing_table.extra_user_charge" | translate }}
                  } @else {
                    @if (_interval === yearly) {
                      {{ "pricing_table.charged_annualy" | translate }}
                    } @else {
                      {{ "pricing_table.charged_monthly" | translate }}
                    }
                  }
                </div>
              } @else {
                @if (_renderMode === website) {
                  <div class="cpt-text-xs cpt-font-medium cpt-text-calenso-steel-blue-color [.popular-plan_&]:cpt-text-calenso-powder-blue-color">
                    {{ "pricing_table.price_on_request" | translate }}
                  </div>
                } @else {
                  <div class="cpt-h-4"></div>
                }
              }
            </span>
          </div>
          <!-- Upgrade button -->
          @if (_renderMode !== sales) {
            <div class="cpt-mt-5">
              <ng-container [ngTemplateOutlet]="planUpgrdeButton" [ngTemplateOutletContext]="{ plan: plan }" />
            </div>
          }
          <!-- Feature list -->
          <div class="cpt-mt-6 [.popular-plan_&]:cpt-text-calenso-white-color">
            <span class="cpt-text-sm md:cpt-text-xs lg:cpt-text-sm cpt-font-bold cpt-mb-5">
              {{ plan.feature_list_title[_language] }}:
            </span>

            @for (feature of plan.feature_list[_renderMode]; track feature[_language]) {
              @if ((feature?.show_user_base_price === true && _showUserBasedPricing) || feature?.show_user_base_price === undefined) {
                <div
                  class="cpt-mt-4 cpt-flex cpt-items-center"
                  [ngClass]="{'cpt-opacity-50': feature?.include === false}"
                >
                  <div
                    class="cpt-bg-calenso-neon-blue-color cpt-flex cpt-justify-center cpt-items-center cpt-w-5 cpt-min-w-5 cpt-h-5 cpt-rounded-[50%] cpt-self-start [.popular-plan_&]:cpt-text-calenso-white-color">
                    @if (_renderMode === website) {
                      <img width="24" height="24" src="{{ deployUrl + '/assets/images/check_pricing_icon_black.svg' }}" alt="checkmark"
                        class="cpt-w-5 cpt-h-5 cpt-min-w-[20px] cpt-bg-calenso-powder-blue-color [.popular-plan_&]:cpt-hidden">
                      <img width="24" height="24" src="{{ deployUrl + '/assets/images/check_pricing_icon_white.svg' }}" alt="checkmark"
                        class="cpt-w-5 cpt-h-5 cpt-min-w-[20px] cpt-hidden cpt-bg-calenso-neon-blue-color [.popular-plan_&]:cpt-block">
                    } @else {
                      @if (feature?.include === false) {
                        <img width="24" height="24" src="{{ deployUrl + '/assets/images/cancel_violet.svg' }}" alt="cancel"
                          class="cpt-w-5 cpt-h-5 cpt-min-w-[20px] cpt-bg-calenso-powder-blue-color [.popular-plan_&]:cpt-hidden">
                        <img width="24" height="24" src="{{ deployUrl + '/assets/images/cancel_white.svg' }}" alt="cancel"
                          class="cpt-w-5 cpt-h-5 cpt-min-w-[20px] cpt-hidden cpt-bg-calenso-neon-blue-color [.popular-plan_&]:cpt-block">
                      } @else {
                        <img width="24" height="24" src="{{ deployUrl + '/assets/images/checkmark_violet.svg' }}" alt="checkmark"
                          class="cpt-w-5 cpt-h-5 cpt-min-w-[20px] cpt-bg-calenso-powder-blue-color [.popular-plan_&]:cpt-hidden">
                        <img width="24" height="24" src="{{ deployUrl + '/assets/images/checkmark_white.svg' }}" alt="checkmark"
                          class="cpt-w-5 cpt-h-5 cpt-min-w-[20px] cpt-hidden cpt-bg-calenso-neon-blue-color [.popular-plan_&]:cpt-block">
                      }
                    }
                  </div>
                  <span
                    class="cpt-ms-3 cpt-text-sm md:cpt-text-xs lg:cpt-text-sm cpt-font-medium cpt-word-break"
                    [innerHTML]="feature[_language] | calioSafe: 'html'"
                  >
                  </span>
                </div>
              }
            }
          </div>
          <!-- Compare feature link -->
          @if (_renderMode === website) {
            <div class="cpt-absolute cpt-bottom-0 cpt-my-2">
              <span
                class="cpt-mt-3 cpt-text-sm md:cpt-text-xs cpt-text-calenso-neon-blue-color cpt-cursor-pointer cpt-font-medium cpt-italic cpt-flex cpt-items-center [.popular-plan_&]:cpt-text-calenso-white-color [.disabled-plan_&]:cpt-pointer-events-none"
                (click)="compareFeatureList()"
              >
                {{ "pricing_table.compare_all_features" | translate }}
                <span
                  class="cpt-ms-4 cpt-block cpt-w-2 cpt-h-2 cpt-border-t cpt-border-solid cpt-border-calenso-neon-blue-color cpt-rotate-[135deg] cpt-border-l after:cpt-content-[''] after:cpt-block after:cpt-w-px after:cpt-h-[15px] after:cpt-bg-calenso-neon-blue-color after:cpt-rotate-[-45deg] after:cpt-translate-x-[5px] after:cpt-translate-y-[-2px] after:cpt-left-0 after:cpt-top-0 [.popular-plan_&]:cpt-border-calenso-white-color [.popular-plan_&]:after:cpt-bg-calenso-white-color"
                ></span>
              </span>
            </div>
          }
        </div>
      }
    </div>
  </div>

  @if (_renderMode === sales) {
    <div
      class="cpt-mt-10 cpt-text-center cpt-text-sm"
      [innerHTML]="'pricing_table.rights_reserved' | translate | calioSafe: 'html'"
    >
    </div>
  }
</div>

<ng-template #planUpgrdeButton let-plan="plan">
  @if (_renderMode === website) {
    @if (plan.id === subscriptionIds.basic) {
      <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'get_start_for_free' }" />
    } @else if (plan.id === subscriptionIds.premium) {
      <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'free_trial' }" />
    } @else if (plan.id === subscriptionIds.business) {
      <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'days_14_free_trial' }" />
    } @else if (plan.id === subscriptionIds.enterprise) {
      <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'contact_us' }" />
    }
  }
  @if (_renderMode === dashboard) {
    @if(!isPartnerInTrialMode) {
      @if (currentPlanPrecedence === pricingPlanPrecedence[plan.id]) {
        @if(_currentSubscription.subscription_interval === monthly && _interval === monthly) {
          <ng-container [ngTemplateOutlet]="emptyButtonText" />
        } @else if (_currentSubscription.subscription_interval === monthly && _interval === yearly) {
          @if (!plan?.price_on_request) {
            <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'change_your_subscription' }" />
          } @else {
            <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'request_now' }" />
          }
        } @else {
          <ng-container [ngTemplateOutlet]="emptyButtonText" />
        }
      } @else if (currentPlanPrecedence > pricingPlanPrecedence[plan.id]) {
        <ng-container [ngTemplateOutlet]="emptyButtonText" />
      } @else if ((currentPlanPrecedence < pricingPlanPrecedence[plan.id]) || !currentPlanPrecedence) {
        @if (!plan?.price_on_request) {
          <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'upgrade_now' }" />
        } @else {
          <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'request_now' }" />
        }
      }
    }@else {
      @if (!plan?.price_on_request) {
        <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'upgrade_now' }" />
      } @else {
        <ng-container [ngTemplateOutlet]="planButtonText" [ngTemplateOutletContext]="{ plan: plan , buttonText: 'request_now' }" />
      }
    }
  }
</ng-template>

<ng-template #planButtonText let-buttonText="buttonText" let-plan="plan">
  <button
    class="cpt-text-base md:cpt-text-sm lg:cpt-text-base cpt-font-medium cpt-w-full cpt-h-12 cpt-bg-calenso-neon-blue-color cpt-text-calenso-white-color cpt-rounded [.popular-plan_&]:cpt-bg-calenso-white-color [.popular-plan_&]:cpt-text-calenso-black-color [.disabled-plan_&]:cpt-pointer-events-none"
    (click)="submit(plan,_interval,_currency)"
  >
    {{ "pricing_table." + buttonText | translate }}
  </button>
</ng-template>

<ng-template #emptyButtonText>
  <div class="cpt-h-12"></div>
</ng-template>

<ng-template #incrementButtonText>
  +
</ng-template>

<ng-template #decrementButtonText>
  -
</ng-template>
